import React from "react";
import Discord from '../assets/Discord Icon.svg'
import Twitter from '../assets/Twitter Icon.svg'
import Medium from '../assets/Medium Icon.svg'
import Doc from '../assets/Doc Icon.svg'

export default function Footer({className}) {
    return (
        <div className={className}>
            <div className="lg:shadow-none lg:pt-10 shadow-mdv text-center lg:text-left">
                <div className="flex flex-row justify-center gap-x-5 lg:p-7 p- items-center">
                    <div><a href="https://discord.gg/ycyr8Fp3Wu" target="_blank" rel="noreferrer"><img src={Discord} alt='discord'/></a></div>
                    <div><a href="https://twitter.com/double2winwin" target="_blank" rel="noreferrer"><img src={Twitter} alt='twitter'/></a></div>
                    <div><a href="https://double2win.medium.com/" target="_blank" rel="noreferrer"><img src={Medium} alt='medium'/></a></div>
                    <div><a href="https://docs.double2win.xyz/" target="_blank" rel="noreferrer"><img src={Doc} alt='doc'/></a></div>
                </div>
            </div>
        </div>
    )
}