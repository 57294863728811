import Logo from '../assets/Logo.svg'

export default function Header({className}) {
  const openLink = () => {
    window.open('https://app.double2win.xyz', '_blank'); // '_blank' opens the link in a new tab/window
  };
  
  return (

    <div className={className}>
      <header className="bg-header-blue lg:bg-main-blue lg:shadow-none shadow-md">
        <nav className="mx-auto flex items-center justify-between lg:p-6 lg:px-10 px-9 p-4" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Double</span>
              <img className="lg:h-8 h-6 w-auto" src={Logo} alt="" />
            </a>
          </div>
          <div className="lg:justify-end rounded-full bg-header-blue lg:bg-main-blue p-1 border border-solid border-white border-2 hover:border-green">
          <button type="button" onClick={openLink} target="_blank" className="text-main-blue lg:text-main-blue bg-white font-medium rounded-full lg:text-sm text-xs lg:px-8 px-5 py-2 text-center">Launch App</button>
          </div>
        </nav>
      </header>
    </div>
  )
}
