import './App.css';
import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';

function App() {
  return (
    <div className="bg-main-blue">
      <Header className="sticky top-0 w-full z-50"/>
      <Body/>
      <Footer className="w-full"/>
    </div>
  );
}

export default App;
