import React, { useEffect, useRef, useState } from "react";
import Group from '../assets/Group.svg'
import DescriiptorABI from "../abi/Descriptor.json";
import { ethers } from "ethers";

const provider = new ethers.providers.JsonRpcProvider(
    "https://eth-sepolia.g.alchemy.com/v2/U8JWe_8VgeLWeos_o4_uu9v49pdrpLKM"
  );
  const CONTRACT_ADDR = "0x1D34e3fC62bC303aD20e09ef622e2CB9F90d8e8A";
  const descriptorContract = new ethers.Contract(
    CONTRACT_ADDR,
    DescriiptorABI,
    provider
  );

export default function Body({className}) {
    const [svgImage, setSVGImage] = useState(null);
    const [opacity, setOpacity] = useState(1);
    const svgRef = useRef();

    useEffect(() => {
        const generateSVGImage = async () => {
            const tokenID = parseInt(Math.random() * 1000);
            const tokenImage = await descriptorContract.generateSVGImage(tokenID);
            const decodedImage = atob(tokenImage);
            setOpacity(0);
            setTimeout(() => {
                const newSVG = new Image();
                newSVG.onload = () => {
                    setSVGImage(newSVG.src);
                    setOpacity(1);
                };
                newSVG.src = `data:image/svg+xml;base64,${btoa(decodedImage)}`;
                setSVGImage(newSVG);
            }, 500);
        }
        const intervalId = setInterval(async () => {
            await generateSVGImage();
        }, 10000);
        
        generateSVGImage();

        return () => {
        clearInterval(intervalId);
        };
    }, []);
    
    const style = {
        opacity: opacity,
        transition: 'opacity 0.5s ease'
    };

    return (
        <div className={className}>
            <div className="lg:flex items-center justify-center text-white lg:h-60vh mb-16 mt-20">
                <div className="lg:basis-6/12 w-full lg:ml-11 pb-7 px-5">
                    <p className="lg:text-6xl text-2xl p-5 lg:px-5 lg:text-left text-center font-bold description-title">Essential Liquidity Infra for L1, ETH L2 & BTC L2</p>
                    <p className="lg:text-2xl px-5 text-md lg:leading-9 text-content-color description-content">
                    Empower crypto AI, DePIN, RWA, and all token projects to solve their on-chain liquidity challenges by offering 300K+ AMM LPs the same benefits exclusive to CEX pro market makers.
                    Attract massive capital into blockchain & AMM ecosystems by offering capital providers positive risk-adjusted yields.
                    </p>
                </div>
                <div className="lg:basis-6/12 py-3 lg:px-7 self-center flex justify-center items-center flex-col container lg:pb-0 pb-30">
                    <img src={Group} alt="Main" className="back-svg"/>
                    { svgImage && 
                        <img src={svgImage} className="svg-container" style={style}/> 
                    }
                </div>
            </div>
        </div>
    )
}